import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { contentError } from 'fast-phonics-client/utils/errors'
import { assert } from '@ember/debug'
import { InvalidError } from '@ember-data/adapter/error'
import type Store from '@ember-data/store'
import type DebugModeService from 'fast-phonics-client/services/debug-mode'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type SessionService from 'fast-phonics-client/services/session'
import type StudentModel from 'fast-phonics-client/models/student'
import type { YetiShopContentActions } from '@blakeelearning/content-specs-fast-phonics'
import type RewardTotalModel from 'fast-phonics-client/models/reward-total'
import type { PurchasedYetis } from 'fast-phonics-client/routes/yeti-shop'

declare module '@ember-data/adapter/error' {
  interface AdapterError {
    errors: { title: string; detail: string }[]
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface InvalidError extends AdapterError {}
}

interface PurchaseYetiResult {
  selectedYeti: string
  coins: number
  purchases: PurchasedYetis[]
}

interface SelectedYeti {
  selectedYeti: string
}

interface YetiData {
  uuid: string
  id: string
}

export default class YetiShopController extends Controller {
  @service declare debugMode: DebugModeService

  @service declare errorHandler: ErrorHandlerService

  @service declare loadingUi: LoadingUiService

  @service declare session: SessionService

  @service declare store: Store

  get student(): StudentModel {
    return this.session.student
  }

  get rewardTotals(): RewardTotalModel {
    assert('rewardTotals is set', this.session.student.rewardTotals)
    return this.session.student.rewardTotals
  }

  get coins(): number {
    assert('coins is set', this.rewardTotals.coins)
    return this.rewardTotals.coins
  }

  get selectedYeti(): string {
    assert('selected yeti is defined', this.student.selectedYeti)
    return this.student.selectedYeti
  }

  get purchases(): PurchasedYetis[] {
    return this.student.purchases.map(({ item, category }) => {
      assert('purchase category exists', category)
      assert('purchase item exists', item)

      return { item, category }
    })
  }

  get activityComponent(): string {
    if (this.debugMode.enabled) {
      return 'debug-mode/yeti-shop'
    }
    return 'content-interactive'
  }

  get contentActions(): YetiShopContentActions {
    return {
      readyForUserInput: () => {
        this.loadingUi.finish()
      },
      unhandledError: ({ error }: { error: unknown }) => {
        this.errorHandler.handleContentUnhandledError(error)
      },
      goBack: () => {
        this.goBack()
      },
      purchaseYeti: (data: YetiData) => this.purchaseYeti(data),
      selectYeti: (data: YetiData) => this.selectYeti(data),
      getCoins: () => this.getCoins(),
    }
  }

  @action goBack(): void {
    this.loadingUi.start(() => {
      window.history.back()
    })
  }

  /**
   * Async method for the yeti shop interactive to call in order to purchase a yeti.
   */
  async purchaseYeti({ uuid, id }: YetiData): Promise<PurchaseYetiResult> {
    if (!uuid) throw new Error("'uuid' required")
    if (!id) throw new Error("'id' required")

    const isExistingUUID = this.store
      .peekAll('purchase')
      .slice()
      .some((item) => item.uuid === uuid)
    if (isExistingUUID) throw new Error('Attempted to reuse UUID')

    const purchase = this.store.createRecord('purchase', {
      uuid,
      category: 'yeti',
      item: id,
      student: this.student,
    })

    try {
      await purchase.save()
    } catch (error) {
      purchase.unloadRecord()
      this.student.notifyPropertyChange('purchases')

      if (error instanceof InvalidError) {
        const [firstError] = error.errors

        if (firstError?.detail === 'Category item has already been created') {
          throw contentError('AlreadyPurchased', error)
        } else if (firstError?.detail === 'not enough coins') {
          throw contentError('NotEnoughCoins', error)
        } else {
          // Any other InvalidError is not processable by Jester
          throw error
        }
      }

      throw contentError('PurchaseFailed', error)
    }

    const { selectedYeti, coins, purchases } = this

    return {
      selectedYeti,
      coins,
      purchases,
    }
  }

  /**
   * Async method for the yeti shop interactive to call in order to select a yeti.
   */
  async selectYeti({ id }: YetiData): Promise<SelectedYeti> {
    const { student } = this
    student.selectedYeti = id

    try {
      await student.save()
    } catch (error) {
      student.rollbackAttributes()
      throw contentError('SelectFailed', error)
    }

    return { selectedYeti: student.selectedYeti }
  }

  async getCoins(): Promise<{ coins: number }> {
    await this.session.reloadStudent({ include: 'reward-totals' })
    return { coins: this.coins }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'yeti-shop': YetiShopController
  }
}
