import type { RumPublicApi } from '@datadog/browser-rum-core'

declare global {
  interface Window {
    DD_RUM?: RumPublicApi
  }
}

/**
 * Used to add extra global context to datadog RUM events.
 */
export function setupDatadogRum() {
  if (!window.DD_RUM) return

  const datadogRum = window.DD_RUM

  try {
    const matches = navigator.userAgent.match(
      /(readingeggs|mathseeds)-(ios|android)(?:(?:[-/]v?)(\S+))?/,
    )

    datadogRum.setGlobalContextProperty('isNativeAppWebView', matches != null)

    if (matches) {
      datadogRum.setGlobalContextProperty('nativeAppBrand', matches[1])
      datadogRum.setGlobalContextProperty('nativeAppPlatform', matches[2])
      datadogRum.setGlobalContextProperty(
        'nativeAppVersion',
        matches[3] ?? 'unknown',
      )
    }
  } catch (error) {
    console.error('Failed to setGlobalContextProperty on window.DD_RUM', error)
  }
}
