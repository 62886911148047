import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FullViewport\n  {{content-interactive\n    this.loader\n    manifests=@manifests\n    variables=this.variables\n    externalController=this.externalController\n    onError=this.onError\n  }}\n  data-test-content-interactive\n  class='flex justify-center items-center'\n  ...attributes\n/>", {"contents":"<FullViewport\n  {{content-interactive\n    this.loader\n    manifests=@manifests\n    variables=this.variables\n    externalController=this.externalController\n    onError=this.onError\n  }}\n  data-test-content-interactive\n  class='flex justify-center items-center'\n  ...attributes\n/>","moduleName":"fast-phonics-client/components/content-interactive.hbs","parseOptions":{"srcName":"fast-phonics-client/components/content-interactive.hbs"}});
import Component from '@glimmer/component'
import { assert } from '@ember/debug'
import { inject as service } from '@ember/service'
import type { ExternalController, Logger } from '@blakeelearning/messenger'
import {
  createExternalController,
  isContentSpec,
  isContentActions,
} from '@blakeelearning/messenger'
import config from 'fast-phonics-client/config/environment'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type { FeatureService } from '@blakeelearning/features'
import type SessionService from 'fast-phonics-client/services/session'
import { activityAccent } from '@blakeelearning/content-loader'
import type InteractiveLoaderService from '@blakeelearning/content-loader/content-loader/interactive-loader/service'
import { action } from '@ember/object'

const {
  contentLoader: { jester: jesterConfig },
} = config

interface ContentInteractiveComponentArgs {
  contentActions: unknown
  contentSpec: unknown
  accentOverride?: string
  manifests: [key: string, url: string][]
  variables: Record<string, unknown>
}

export default class ContentInteractive extends Component<ContentInteractiveComponentArgs> {
  @service declare errorHandler: ErrorHandlerService

  @service declare features: FeatureService

  @service declare session: SessionService

  @service('content-loader/interactive-loader')
  declare interactiveLoader: InteractiveLoaderService

  get loader() {
    return this.interactiveLoader.createJesterLoader({
      variant: activityAccent(this.args.accentOverride ?? this.session.accent),
      config: jesterConfig,
    })
  }

  get externalController(): ExternalController {
    const { contentActions, contentSpec } = this.args

    assert(
      '@contentSpec must be a valid contentSpec object',
      isContentSpec(contentSpec),
    )

    assert(
      '@contentActions must be a valid contentActions object for this contentSpec',
      isContentActions(contentActions, contentSpec),
    )

    const {
      APP: { enableMessengerLogging },
    } = config

    let logger: Logger | undefined

    if (enableMessengerLogging) {
      logger = (name, event, ...args) => {
        console.log(
          '%c[%s :: %s]',
          'color: lavender; background-color: #836bb6; font-weight: bold',
          name,
          event,
          ...args,
        )
      }
    }

    return createExternalController(contentActions, contentSpec, {
      onUnrecoverableError: (error) => {
        this.errorHandler.handleUnrecoverableError(error)
      },
      logger,
    })
  }

  get variables() {
    const client = this.features.getClient()
    return {
      ...this.args.variables,
      features: ['jesterLoaderRetryFF', 'jesterCssFontLoadingFF'].filter(
        (flag) => client.getBooleanValue(flag, false),
      ),
    }
  }

  @action
  onError(error: unknown) {
    this.errorHandler.handleUnrecoverableError(error)
  }
}
