import type Store from '@ember-data/store'
import type { Snapshot } from '@ember-data/store'
import ApplicationAdapter from 'fast-phonics-client/adapters/application'
import { retryOnNetworkError } from 'fast-phonics-client/utils/retry-on-network-error'
import type ModelRegistry from 'ember-data/types/registries/model'
import type RSVP from 'rsvp'

// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelSchema } from 'ember-data'

export default class Adapter extends ApplicationAdapter {
  override urlForCreateRecord<K extends keyof ModelRegistry>(
    modelName: K,
    snapshot: Snapshot,
  ) {
    const studentId = (snapshot as Snapshot<'peak-quiz-result'>).belongsTo(
      'student',
    )?.id

    return `${this.buildURL('student', studentId)}/${this.pathForType(
      modelName,
    )}`
  }

  override createRecord<K extends keyof ModelRegistry>(
    store: Store,
    type: ModelSchema<K>,
    snapshot: Snapshot,
  ) {
    const createRecord = async () => {
      const result: unknown = await super.createRecord(store, type, snapshot)
      return result
    }

    return retryOnNetworkError(createRecord) as RSVP.Promise<unknown>
  }
}
